import React from "react"
// Using an ES6 transpiler like Babel
import useVacancy from "src/server/queryHooks/useVacancy"
import FilterRangeSliderBig from "src/views/components/RangeSlider/FilterRangeSliderBig"

// To include the default styles

const RangeSliderContainer = () => {
  const { vacancyCountQuery } = useVacancy()
  if (!vacancyCountQuery.isFetched) {
    vacancyCountQuery.refetch()
  }
  const vacancyCount = vacancyCountQuery.data

  if (vacancyCount === undefined) {
    return <div>Loading...</div>
  }

  return (
    <div className="">
      <FilterRangeSliderBig highest_salary={Number(vacancyCount.highest_salary)} salaries={vacancyCount.salaries_desktop} />
    </div>
  )
}

export default RangeSliderContainer
