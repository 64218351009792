import React, { forwardRef } from "react"
import Typography from "../../../typography"
import Image from "next/image"
import { cx } from "class-variance-authority"
import { twMerge } from "tailwind-merge"

interface Props {
  name: string
  label: string
  selected: boolean
  className?: string
  onClick?: () => void
  color?: string
  noHover?: boolean
  indeterminate?: boolean
}

const DropdownCheckbox = forwardRef<HTMLInputElement, Props>(
  ({ name, label, selected, onClick, color, noHover, className, indeterminate, ...rest }, ref) => {
    return (
      <div
        className={twMerge(
          cx(
            "relative flex w-full  select-none items-center rounded-lg px-4 py-3  transition-all",
            className,
            noHover ? "" : "cursor-pointer hover:bg-neutral-10"
          )
        )}>
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={selected}
          onClick={onClick}
          ref={ref}
          className={cx(
            `peer relative h-5 w-5 flex-shrink-0 cursor-pointer appearance-none rounded border border-neutral-50 bg-white `,
            color ? "checked:border-primary-100 checked:bg-primary-100" : "checked:border-black checked:bg-black"
          )}
          {...rest}
        />
        {selected &&
          (!indeterminate ? (
            <Image
              src="/images/bi_check.svg"
              width={13}
              height={13}
              className="pointer-events-none absolute ml-1 hidden peer-checked:block"
              alt="check"
            />
          ) : (
            <div className="pointer-events-none absolute flex h-5 w-5 items-center justify-center  text-white">-</div>
          ))}

        <label className="cursor-pointer pl-2.5" onClick={onClick}>
          <Typography type="text">{label}</Typography>
        </label>
      </div>
    )
  }
)
export default DropdownCheckbox
