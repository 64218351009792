import Tippy from "@tippyjs/react"
import { cx } from "class-variance-authority"
import React, { FC } from "react"
import { useTranslation } from "next-i18next"
import Typography from "src/views/components/typography"

interface Props {
  salaries: { from: number; to: number; count: number }[]
  current_min: number
  current_max: number
  most_counted_salary: number
  setVolume: (value: any) => void
  className?: string
  handleChange: (value: any) => void
}
const RangeSliderBars: FC<Props> = ({ salaries, current_min, className, setVolume, current_max, most_counted_salary, handleChange }) => {
  const { t } = useTranslation("common")

  const calculateBarHeight = (salary: number) => {
    return (salary / most_counted_salary) * 100
  }
  return (
    <div className={cx("flex h-16 items-end", className)}>
      {salaries.map((salary, index) => {
        return (
          <Tippy
            key={index}
            className="w-22"
            content={
              <Typography type="small" className="text-center text-white">
                {salary.count} {t("VACANCY")} {salary.from} - {salary.to}
              </Typography>
            }
            placement="top">
            <div
              onClick={() => handleChange([salary.from, salary.to])}
              key={index}
              className={cx(
                "w-full min-w-[1px] cursor-pointer rounded-t-[4px] bg-black",

                salary.to < current_min && "bg-neutral-40",
                salary.from > current_max && "bg-neutral-40"
              )}
              style={{
                height: `${calculateBarHeight(salary.count)}%`
              }}
            />
          </Tippy>
        )
      })}
    </div>
  )
}

export default RangeSliderBars
