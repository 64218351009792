import Tippy from "@tippyjs/react"
import Image from "next/image"
import { useRouter } from "next/router"
import { useState } from "react"
import { IOptionJobType } from "src/utils/data/phisical-register-data"
import SearchboxFilterModal from "src/views/components/SearchboxFilterModal/SearchboxFilterModal"
import { SearchBoxActionButtons, SearchBoxInterfaceBox, SearchBoxSearchButton } from "./SearchBox.styles"
import JobTypeDropdown from "./SearchInputDropdowns/JobTypeDropdown"
import KeywordSearchDropdown from "./SearchInputDropdowns/KeywordSearchDropdown"
import LocationSearchDropdown from "./SearchInputDropdowns/LocationSearchDropdown"
import { useSearch } from "src/views/pages/index/SearchBoxNew/hooks/SearchContext"
import { sendFiltersGoogleAnalytics } from "../sendFiltersGoogleAnalytics"

interface Props {
  style_type?: "vacancy" | "main"
}
const SearchBox = ({ style_type = "main" }: Props) => {
  const router = useRouter()
  const { filters } = useSearch()

  // * State
  const [selectedJobType, setSelectedJobType] = useState<IOptionJobType[]>([])
  const [selectedLocations, setSelectedLocations] = useState<any>([])
  const [selectedFilters, setSelectedFilters] = useState<any>({})
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false)
  const [selectedCompanyId, setSelectedCompanyID] = useState<number | null>(null)
  const [selectedKeyword, setSelectedKeyword] = useState<string | null>(null)
  const [query, setQuery] = useState("")

  const handeKeywordOptionClick = (option: any) => {
    if (option.type === "company") {
      setSelectedCompanyID(option.company_id)
      setSelectedKeyword(null)
    }

    if (option.type === "keyword") {
      setSelectedKeyword(option.value)
      setSelectedCompanyID(null)
    }
  }

  const performSearch = (keyword?: string) => {
    setMobileFilterOpen(false)
    const queryParams = new URLSearchParams()

    for (const filterName in selectedFilters) {
      if (Array.isArray(selectedFilters[filterName])) {
        queryParams.append(filterName, selectedFilters[filterName].join(","))
      } else {
        queryParams.append(filterName, selectedFilters[filterName])
      }
    }

    queryParams.append("job_types", selectedJobType.map(jobType => jobType.value).join(","))
    queryParams.append("countries", selectedLocations.join(","))
    queryParams.append("company_id", selectedCompanyId?.toString() || "")
    if (keyword && typeof keyword === "string") {
      queryParams.append("keyword", keyword)
    } else {
      queryParams.append("keyword", selectedKeyword || query || "")
    }

    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        queryParams.append(key, filters[key])
      }
    }
    sendFiltersGoogleAnalytics(queryParams)
    router.push({ pathname: "/vacancy", search: queryParams.toString() })
  }

  const openFilterModal = () => {
    if (window.innerWidth < 736) {
      setMobileFilterOpen(true)
    }
  }

  return (
    <SearchBoxInterfaceBox className="box-wrapper" style_type={style_type}>
      <div onClick={openFilterModal} className="search-wrapper flex flex-1">
        <KeywordSearchDropdown
          performSearch={performSearch}
          style_type={style_type}
          handeKeywordOptionClick={handeKeywordOptionClick}
          setQuery={setQuery}
          query={query}
        />
        <JobTypeDropdown style_type={style_type} setSelectedJobType={setSelectedJobType} selectedJobType={selectedJobType} />
        <LocationSearchDropdown style_type={style_type} setSelectedLocationsParams={setSelectedLocations} />
      </div>

      {(style_type === "main" || style_type === "vacancy") && (
        <SearchBoxActionButtons className="filter-buttons">
          <Tippy content={<span>ფილტრები</span>}>
            <SearchboxFilterModal
              style_type={style_type}
              isMobileOpen={mobileFilterOpen}
              setIsMobileOpen={setMobileFilterOpen}
              setSelectedFilters={setSelectedFilters}
              selectedFilters={selectedFilters}
            />
          </Tippy>
          {style_type !== "vacancy" && (
            <SearchBoxSearchButton onClick={() => performSearch()}>
              <Image src="/images/search.svg" className="h-3.5 w-3.5 md:h-[18.6px] md:w-[18.6px]" width={18.6} height={18.6} alt="search" />
            </SearchBoxSearchButton>
          )}
        </SearchBoxActionButtons>
      )}
    </SearchBoxInterfaceBox>
  )
}

export default SearchBox
