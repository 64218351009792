import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Transition } from "@headlessui/react"
import { cx } from "class-variance-authority"
import { useTranslation } from "next-i18next"
import Image from "next/image"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useLanguagesCountriesQuery } from "src/query-library/queries/useLanguagesCountriesQuery"
import DropdownCheckbox from "src/views/components/common/form-controll/Checkbox/DropdownCheckbox"
import Typography from "src/views/components/typography"
import { ComboboxNotFoundText } from "../SearchBox.styles"
import Xclear from "./Xclear"
import { transliterate } from "transliteration"
import { cn } from "src/utils/cn"

interface Props {
  style_type: "vacancy" | "main"
  setSelectedLocationsParams: any
}

const LocationSearchDropdown = ({ style_type, setSelectedLocationsParams }: Props) => {
  // * Hooks
  const router = useRouter()
  const { t } = useTranslation("common")

  // * State
  const [selectedLocations, setSelectedLocations] = useState<string[]>([])
  const [selectedLocationIds, setSelectedLocationIds] = useState<number[]>([])

  // * Queries
  const langCountriesQuery = useLanguagesCountriesQuery()
  const georgiaCountry = langCountriesQuery?.data?.countries?.[0]

  const clear = () => {
    setSelectedLocations([])

    // This was not added here, idk why maybe this is breaking something. I will check it later (lie).
    setSelectedLocationIds([])

    if (router.pathname.includes("vacancy")) {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, countries: "" }
      })
    }
  }

  useEffect(() => {
    const param = router.query.countries
    setSelectedLocationIds([])
    setSelectedLocations([])
    if (param && typeof param === "string") {
      const locationIds = param.split(",").map(id => parseInt(id.trim(), 10))
      console.log("🚀 ~ useEffect ~ locationIds:", locationIds)
      const locationTitles: string[] = []
      if (georgiaCountry && locationIds.includes(georgiaCountry.id)) locationTitles.push(georgiaCountry.title)

      if (georgiaCountry?.children) {
        georgiaCountry.children.forEach(city => {
          if (locationIds.includes(city.id)) locationTitles.push(city.title)
        })
      }
      setSelectedLocations(locationTitles)
      setSelectedLocationIds(locationIds)
    }
  }, [router.query.countries, langCountriesQuery.isSuccess])

  const handleChange = (event: any) => {
    if (router.pathname.includes("vacancy")) {
      const currentQuery: any = { ...router.query, countries: "" }

      delete currentQuery.page

      if (event.length) currentQuery["countries"] = event.join(",")

      router.push({
        pathname: router.pathname,
        query: currentQuery
      })
    }
    setSelectedLocationsParams(event)
  }

  const [query, setQuery] = useState("")

  const filteredCitiesArray =
    query === "" && georgiaCountry
      ? georgiaCountry.children
      : georgiaCountry?.children.filter(city => {
          const cityTitle = city.title.toLowerCase().replace(/\s+/g, "")
          const queryTitle = query.toLowerCase().replace(/\s+/g, "")
          return (
            cityTitle.includes(queryTitle) ||
            transliterate(cityTitle)
              .replace(/[^\w\s]/gi, "")
              .includes(queryTitle)
          )
        })

  return (
    <Combobox value={selectedLocations} as="div" className={"relative hidden h-full flex-1 md:flex md:flex-1 location-dropdown"} multiple>
      {({ open }) => (
        <>
          <ComboboxButton className={"relative flex h-full w-full cursor-pointer items-center pl-4 pr-4 focus:outline-none"}>
            <Image src={"/images/place.svg"} width={24} height={24} className="mr-2" alt={"place"} />
            <ComboboxInput
              autoComplete="off"
              placeholder={selectedLocations.length ? selectedLocations.join(", ") : t("LOCATION")}
              className={cn(
                " h-full w-full cursor-pointer bg-inherit py-1 text-2sm text-secondary-100 placeholder:font-tbcx-regular placeholder:text-2sm placeholder:text-secondary-50",
                style_type === "vacancy" && "border-neutral-40",
                selectedLocationIds.length > 0 && !open ? "placeholder:text-black" : ""
              )}
              // displayValue={(options: string[]) => options.join(", ")}
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </ComboboxButton>
          {/* X clear button */}
          <Xclear show={selectedLocations.length > 0} clear={clear} />

          <Transition
            as={"div"}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className={cx("absolute z-10 mt-2.5", style_type === "main" ? "top-[72px] " : "top-[60px] ")}
            afterLeave={() => setQuery("")}>
            <ComboboxOptions
              className={"-ml-28 max-h-[400px] w-[482px] overflow-auto rounded-[14px] border border-neutral-20 bg-white shadow-card-box"}>
              {(filteredCitiesArray?.length === 0 && query !== "") || !georgiaCountry ? (
                <ComboboxNotFoundText className="p-6">{t("NOT_FOUND")}</ComboboxNotFoundText>
              ) : (
                <div className="p-2.5">
                  <div className="px-4 py-3">
                    <Typography type="text" weight="medium" className="text-secondary-70">
                      {georgiaCountry.title}
                    </Typography>
                  </div>
                  {!query && (
                    <ComboboxOption
                      key={t("SELECT_ALL")}
                      value={t("SELECT_ALL")}
                      onClick={() => {
                        const isAnyCitySelected = georgiaCountry?.children?.some(city => selectedLocations.includes(city.title))
                        if (isAnyCitySelected) {
                          // If any city is selected, deselect all cities of this country
                          setSelectedLocations(prevLocations =>
                            prevLocations.filter(
                              location =>
                                !georgiaCountry?.children?.map(city => city.title).includes(location) && location != georgiaCountry.title
                            )
                          )
                          setSelectedLocationIds(prevLocations => [
                            ...prevLocations.filter(
                              location =>
                                !georgiaCountry?.children?.map(city => city.id).includes(location) && location != georgiaCountry.id
                            )
                          ])
                          handleChange(
                            selectedLocationIds.filter(
                              location =>
                                !georgiaCountry?.children?.map(city => city.id).includes(location) && location != georgiaCountry.id
                            )
                          )
                        } else {
                          // If no city is selected, select all cities of this country
                          setSelectedLocations(prevLocations => [
                            georgiaCountry.title,
                            ...prevLocations,
                            ...(georgiaCountry?.children?.map(city => city.title) ?? [])
                          ])
                          setSelectedLocationIds(prevLocations => [
                            georgiaCountry.id,
                            ...prevLocations,
                            ...(georgiaCountry?.children?.map(city => city.id) ?? [])
                          ])
                          handleChange([
                            ...selectedLocationIds,
                            ...(georgiaCountry?.children?.map(city => city.id) ?? []),
                            georgiaCountry.id
                          ])
                        }
                      }}>
                      <DropdownCheckbox
                        indeterminate={selectedLocations.length !== georgiaCountry?.children?.length + 1}
                        key={georgiaCountry.title}
                        name={t("SELECT_ALL")}
                        label={t("SELECT_ALL")}
                        selected={selectedLocations.length > 0}
                        className="px-12"
                      />
                    </ComboboxOption>
                  )}
                  {filteredCitiesArray?.map((city, idx) => (
                    <div key={city.title + idx}>
                      <ComboboxOption
                        key={city.id}
                        value={city.title}
                        onClick={() => {
                          if (selectedLocations.includes(city.title)) {
                            setSelectedLocations(prevLocations => prevLocations.filter(location => location !== city.title))
                            setSelectedLocationIds(prevLocations => prevLocations.filter(location => location !== city.id))
                            handleChange([selectedLocationIds.filter(location => location !== city.id)])
                          } else {
                            setSelectedLocations(prevLocations => [...prevLocations, city.title])
                            setSelectedLocationIds(prevLocations => [...prevLocations, city.id])
                            handleChange([...selectedLocationIds, city.id])
                          }
                        }}>
                        {({ active, selected }) => (
                          <DropdownCheckbox key={city.title} name={city.title} label={city.title} selected={selected} className="px-12" />
                        )}
                      </ComboboxOption>
                    </div>
                  ))}
                </div>
              )}
            </ComboboxOptions>
          </Transition>
        </>
      )}
    </Combobox>
  )
}

export default LocationSearchDropdown
// {filteredOptions.map(country => (
//   <Disclosure key={country.id} defaultOpen={country.title === 'საქართველო'}>
//     {({ open }) => (
//       <>
//         <Disclosure.Button className='flex items-center gap-2 p-4'>
//           <Image
//             src={`/images/expand_more.svg`}
//             width={24}
//             height={24}
//             alt={`arrow`}
//             className={cx(
//               'translate transition-all duration-300 ease-in-out',
//               open ? '-rotate-180' : 'rotate-0'
//             )}
//           />
//           <Typography type='text' weight='medium'>
//             {country.title}
//           </Typography>
//         </Disclosure.Button>
//         <Disclosure.Panel className='text-gray-500' as='ul'>
//           {country.children &&
//             country.children.map(city => (
//               <Combobox.Option key={city.id} value={city.title} as='li'>
//                 {({ active, selected }) => (
//                   <CheckboxLabel
//                     key={city.title}
//                     name={city.title}
//                     label={city.title}
//                     selected={selected}
//                     className='px-12'
//                   />
//                 )}
//               </Combobox.Option>
//             ))}
//         </Disclosure.Panel>
//       </>
//     )}
//   </Disclosure>
// ))}
