import React, { FC, useEffect, useState } from "react"
// Using an ES6 transpiler like Babel
import { Range } from "react-range"
import useVacancy from "src/server/queryHooks/useVacancy"
import Input from "src/views/components/common/form-controll/Input"
import RangeSliderBars from "src/views/components/RangeSlider/RangeSliderBars"
import Typography from "src/views/components/typography"
import { useSearch } from "src/views/pages/index/SearchBoxNew/hooks/SearchContext"
import Checkbox from "../common/form-controll/Checkbox/DropdownCheckbox"
import { cx } from "class-variance-authority"
import { useTranslation } from "next-i18next"

// To include the default styles

interface Props {
  highest_salary: number
  salaries: { from: number; to: number; count: number }[]
}
const FilterRangeSliderBig: FC<Props> = ({ highest_salary, salaries }) => {
  const { t } = useTranslation("common")
  const [volume, setVolume] = React.useState([0, highest_salary])
  const { setFilters, filters } = useSearch()
  const handleChange = (value: any) => {
    console.log("🚀 ~ handleChange ~ value:", value)
    setVolume(value)
    setFilters({ salary_from: value[0], salary_to: value[1] })
  }
  const { vacancyCountQuery } = useVacancy()
  const [isNegotiable, setIsNegotiable] = useState<boolean>(false)
  const vacancyCount = vacancyCountQuery.data

  useEffect(() => {
    if (isNegotiable) {
      setFilters({ negotiable: isNegotiable })
    } else {
      const { negotiable, ...restFilters } = filters
      setFilters(restFilters)
    }
  }, [isNegotiable])

  if (vacancyCount === undefined) {
    return <div>Loading...</div>
  }
  const most_count_salaries = salaries.reduce((prev, current) => (prev.count > current.count ? prev : current))
  const most_counted_salary = most_count_salaries.count

  const changeNegotiable = () => {
    setIsNegotiable(prevState => !prevState)
  }

  return (
    <div className="salary-range mx-auto w-[896px] bg-transparent">
      <Typography type="h4" className="mb-6">
        {t("SALARY_RANGE")}
      </Typography>
      <RangeSliderBars
        handleChange={handleChange}
        className={cx("salary-range-slider h-[100px] gap-[3px] px-[72px]", isNegotiable ? "pointer-events-none opacity-50" : "")}
        salaries={salaries}
        current_min={volume[0]}
        current_max={volume[1]}
        most_counted_salary={most_counted_salary}
        setVolume={setVolume}
      />
      <div className={cx("salary-range-bars px-[72px]", isNegotiable ? "opacity-50" : "")}>
        <Range
          min={0}
          max={highest_salary}
          step={1}
          values={volume}
          disabled={isNegotiable}
          onChange={handleChange}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "2px",
                width: "100%",
                backgroundColor: "#222"
              }}>
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "32px",
                width: "32px",
                borderRadius: "100%",
                backgroundColor: "white",
                border: "1px solid #ddd",
                filter: "drop-shadow(0px 5px 25px rgba(0, 0, 0, 0.05))"
              }}
            />
          )}
        />
      </div>

      <div className="mt-10 flex items-center gap-4 md:px-[72px]">
        <Input
          label={t("MINIMUM")}
          name="salary_from"
          value={volume[0]}
          readOnly={isNegotiable}
          className={cx(isNegotiable ? "opacity-50" : "", volume[0] > volume[1] ? "input-error" : "")}
          onChange={(value: string) => {
            setVolume([Number(value), volume[1]])
            handleChange([Number(value), volume[1]])
          }}
        />
        <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg" className="shrink-0">
          <line y1="0.780029" x2="16" y2="0.780029" stroke="#DDDDDD" />
        </svg>

        <Input
          label={t("MAXIMUM")}
          name="salary_to"
          value={volume[1]}
          readOnly={isNegotiable}
          className={cx(isNegotiable ? "opacity-50" : "", volume[0] > volume[1] ? "input-error" : "")}
          onChange={(value: string) => {
            setVolume([volume[0], Number(value)])
            handleChange([volume[0], Number(value)])
          }}
        />
      </div>
      <div>
        <Checkbox
          className="-mb-2 mt-4 md:px-[72px]"
          label={t("NEGOTIABLE")}
          name={t("NEGOTIABLE")}
          selected={isNegotiable}
          noHover
          onClick={() => changeNegotiable()}
        />
      </div>
    </div>
  )
}

export default FilterRangeSliderBig
