import { createContext, FC, useContext, useState } from "react"

interface SearchContextValue {
  searchQuery: string
  setSearchQuery: (query: string) => void
  filters: {
    [key: string]: any
  }
  setFilters: (filters: object) => void
}

const SearchContext = createContext<SearchContextValue | undefined>(undefined)

export const SearchProvider: FC<any> = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [filters, setFilters] = useState<{ [key: string]: any }>({})

  return (
    <SearchContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        filters,
        setFilters
      }}>
      {children}
    </SearchContext.Provider>
  )
}

export const useSearch = () => {
  const context = useContext(SearchContext)
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider")
  }
  return context
}
